<template>
  <v-container
    id="dataexport"
    fluid
    tag="section"
    class="grey lighten-4"
  >
    <!-- Dashboard Block Info' toolbar -->
    <v-toolbar
      style="z-index: 1"
      color="elevation-0"
      dense
    >
      <v-toolbar-title class="text-h2 grey--text text--darken-0 pr-8">
        {{ $store.state.main.af.title }} - {{ $t('mpref.dset-dwnl') }}
      </v-toolbar-title>
      <v-spacer />

      <!-- Include Datasets for download, open and close card -->
      <v-btn
        v-if="myRole() === 'manager'"
        color="warning"
        fab
        dark
        @click="helptoolbar = !helptoolbar"
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- Dashboard Datasets Info -->
    <v-card
      v-if="!helptoolbar"
      class="mt-0"
      color="elevation-0"
    >
      <v-row class="px-4">
        <!-- First column -->
        <v-col
          cols="12"
          md="9"
        >
          <v-card-text
            v-for="(dset, i) in dDatasets"
            :key="i"
            class="py-0"
            fluid
          >
            <v-checkbox
              v-model="dset.selected"
              class="mt-2"
              :label="translateMe(dset.label)"
              :prepend-icon="dset.icon"
              :readonly="dset.readonly"
              hide-details
              :disabled="myRole() === 'viewer' || loadingdata"
              @click="dDatasetSelected({ item: i, state: dset.selected, pack: dset.pack})"
            />
            <!-- <div
              class="text--disabled"
            >
              {{ dset }}
            </div> -->
          </v-card-text>
        </v-col>
        <!-- Second column -->
        <v-col
          cols="12"
          md="3"
        >
          <!-- Download Multimedia -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="info"
                v-bind="attrs"
                :disabled="!formMediaState"
                v-on="on"
                @click="dialogGetmedia = true"
              >
                <v-icon left>
                  mdi-file-download
                </v-icon>
                {{ $t('export.media-files') }}
              </v-btn>
            </template>
            <span>{{ $t('export.download-media') }}</span>
          </v-tooltip>
          <v-select
            v-model="selectedLang"
            :items="langList"
            item-text="short"
            item-value="code"
            prepend-icon="mdi-web"
            :label="$t('export.select-lang')"
            return-object
          />
        </v-col>
      </v-row>
      <v-card-actions class="ma-3">
        <v-row>
          <!-- Restore button -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                color="warning"
                :loading="loading"
                v-bind="attrs"
                v-on="on"
                @click="dialogRestore = true"
              >
                <v-icon left>
                  mdi-restore
                </v-icon>
                {{ $t('mpref.dset-restore') }}
              </v-btn>
            </template>
            <span>{{ $t('mpref.dset-restore-tooltip') }}</span>
          </v-tooltip>
          <v-spacer />
          <!-- Keep closed -->
          <v-checkbox
            v-model="dBInfo"
            :label="$t('dashboard.keep-closed')"
          />
        </v-row>
      </v-card-actions>
    </v-card>
    <div
      v-if="loadingdata"
      class="loading"
    >
      <v-progress-linear
        indeterminate
        color="info"
      />
      <!-- ...{{ $t('tooltip.loading') }} -->
    </div>

    <!-- Activate it while getting data -->
    <!-- <v-overlay :value="loadingdata">
      <v-progress-circular

        indeterminate
        size="64"
      />
    </v-overlay> -->

    <!-- Download Datasets -->
    <v-row
      class="d-flex justify-space-between mt-8"
    >
      <base-material-card
        v-for="(type, i) in finalDataset"
        :key="i"
        v-model="finalDataset"
        :color="type.color"
        :icon="type.icon"
        width="270"
        inline
        class="mx-4"
        hover-reveal
      >
        <template v-slot:reveal-actions>
          <div class="d-flex justify-space-between mb-1">
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  class="mx-3"
                  v-bind="attrs"
                  icon
                  dark
                  :disabled="type.loading"
                  v-on="on"
                  @click="genDataset(type.pack)"
                >
                  <v-icon
                    size="20"
                    color="indigo"
                  >
                    mdi-folder-refresh
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('dashboard.ddset-gen') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <div class="display-2 font-weight-light grey--text">
                  <v-btn
                    :download="syncLinks.stata"
                    class="mx-1"
                    v-bind="attrs"
                    icon
                    dark
                    :loading="type.loading"
                    color="primary"
                    v-on="on"
                    @click="dataLinks(type)"
                  >
                    <v-icon
                      color="grey darken-1"
                      large
                    >
                      mdi-folder-download
                    </v-icon>
                  </v-btn>{{ type.title }}
                </div>
              </template>
              <span>{{ $t('common.download') }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:actions>
          <!-- {{ dateDiffInHours(type.syncdate) }} -->
          <span
            v-if="type.syncdate !== '' && dateDiffInHours(type.syncdate) < 24"
            class="caption grey--text font-weight-light"
          >
            <v-icon
              class="mr-1 mt-0"
              small
            >
              mdi-clock-outline
            </v-icon>
            {{ $t('dashboard.generated') }}
            <timeago
              :datetime="type.syncdate"
              :locale="$i18n.locale"
              :auto-update="60"
            />
          </span>
          <span
            v-else
            class="caption grey--text font-weight-light"
          >
            <v-icon
              class="mr-1 mt-0"
              small
              @click="genDataset(type.pack)"
            >
              mdi-folder-refresh-outline
            </v-icon>
            {{ $t('dashboard.ddset-gen') }}
          </span>
        </template>
      </base-material-card>
    </v-row>

    <!-- DIALOGS -->

    <!-- Restore Dataset Dialog -->
    <v-dialog
      v-model="dialogRestore"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          {{ $t('mpref.dset-restore') }}
        </v-card-title>
        <v-card-text>
          {{ $t('mpref.dset-restore-note') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="grey darken-1"
            @click="dialogRestore = false"
          >
            {{ $t('common.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="restoreDataset()"
          >
            {{ $t('common.restore') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dashbord Blocks Select variable Dialog -->
    <v-dialog
      v-model="dialogGis"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          {{ $t('mpref.select-var') }}
        </v-card-title>
        <v-card-text>
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(gisSelected)">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('common.table')"
                rules="required"
              >
                <v-select
                  v-model="selectedSection"
                  :items="dataSet"
                  :error-messages="errors"
                  item-text="label[0]"
                  item-value="table[0]"
                  prepend-icon="mdi-table"
                  :label="$t('mpref.select-tbl')"
                  return-object
                  validate-on-blur
                  @change="sectionSelected"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="t.title"
                rules="required"
              >
                <!-- Used for collecting question from select list -->
                <v-autocomplete
                  v-model="lat"
                  :items="questions"
                  :error-messages="errors"
                  item-text="label"
                  item-value="id"
                  prepend-icon="mdi-map-marker-plus"
                  :label="$t('mpref.lat')"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="t.title"
                rules="required"
              >
                <!-- Used for collecting question from select list -->
                <v-autocomplete
                  v-model="lon"
                  :items="questions"
                  :error-messages="errors"
                  item-text="label"
                  item-value="id"
                  prepend-icon="mdi-map-marker-plus"
                  :label="$t('mpref.lon')"
                />
              </validation-provider>

              <v-row>
                <v-btn
                  text
                  @click="gisCancelled('aahh')"
                >
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-spacer />
                <v-btn
                  color="primary"
                  text
                  type="submit"
                >
                  {{ $t('common.save') }}
                </v-btn>
              </v-row>
            </form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Get Multimedia Dialog -->
    <v-dialog
      v-model="dialogGetmedia"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          {{ $t('export.download-media') }}
        </v-card-title>
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(getMedia)">
            <v-card-text>
              <v-radio-group
                v-model="dwnoption"
                row
                class="ml-2"
              >
                <v-radio
                  :label="$t('export.single-submission')"
                  value="uuid"
                />
                <v-radio
                  :label="$t('export.by-date')"
                  value="date"
                />
              </v-radio-group>
              <!-- Get media by UUID -->
              <v-row v-if="dwnoption === 'uuid'">
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="UUID (KEY) of Submission"
                    rules="required"
                  >
                    <v-text-field
                      v-model="uuid"
                      :error-messages="errors"
                      label="Type UUID (KEY) of the submission"
                      prepend-icon="mdi-book-arrow-down-outline"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <!-- Pick start date and time -->
              <v-row v-if="dwnoption === 'date'">
                <v-col
                  cols="12"
                  sm="12"
                  md="5"
                >
                  <v-checkbox
                    v-model="checkstart"
                    :label="$t('export.custom-start-date')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-menu
                    v-model="sdmenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :disabled="!checkstart"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="sdate"
                        :label="$t('export.select-date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        :disabled="!checkstart"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="sdate"
                      :min="$store.state.main.af.created"
                      :max="maxdate"
                      @input="sdmenu = false"
                    />
                  </v-menu>
                </v-col>
                <v-spacer />
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-menu
                    ref="startmenu"
                    v-model="stmenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="stime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="stime"
                        :label="$t('export.select-time')"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        :disabled="!checkstart"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-time-picker
                      v-if="stmenu"
                      v-model="stime"
                      full-width
                      @click:minute="$refs.startmenu.save(stime)"
                    />
                  </v-menu>
                </v-col>
              </v-row>

              <!-- Pick end date and time -->
              <v-row v-if="checkstart && dwnoption === 'date'">
                <v-col
                  cols="12"
                  sm="12"
                  md="5"
                >
                  <v-checkbox
                    v-model="checkend"
                    :label="$t('export.custom-end-date')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-menu
                    v-model="edmenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :disabled="!checkend"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="edate"
                        :label="$t('export.select-date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        :disabled="!checkend"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="edate"
                      :min="$store.state.main.af.created"
                      :max="maxdate"
                      @input="edmenu = false"
                    />
                  </v-menu>
                </v-col>
                <v-spacer />
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-menu
                    ref="endmenu"
                    v-model="etmenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="etime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="etime"
                        :label="$t('export.select-time')"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        :disabled="!checkend"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-time-picker
                      v-if="etmenu"
                      v-model="etime"
                      full-width
                      @click:minute="$refs.endmenu.save(etime)"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                @click="dialogGetmedia = false"
              >
                {{ $t('common.cancel') }}
              </v-btn>
              <v-spacer />
              <v-btn
                color="primary"
                text
                type="submit"
              >
                {{ $t('common.download') }}
              </v-btn>
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>

    <!-- New SnackBarCard -->
    <snack-bar-card :snackbar="snackBar" />
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import userAccess from '@/mixins/user-access'
  import dashboard from '@/mixins/dashboard'
  import dataExport from '@/mixins/data-export'
  import SnackBarCard from '@/components/SnackbarCard'
  import VueTimeago from 'vue-timeago'
  import axios from 'axios'

  Vue.use(VueTimeago, {
    name: 'Timeago', // Component name, `Timeago` by default
    locale: 'en-US', // Default locale
    locales: {
      pt: require('date-fns/locale/pt'),
      ru: require('date-fns/locale/ru'),
      tr: require('date-fns/locale/tr'),
    },
  })

  export default {
    name: 'DataExport',

    components: {
      SnackBarCard,
    },

    mixins: [
      userAccess,
      dashboard,
      dataExport,
    ],

    data () {
      return {
        helptoolbar: true,

        // dDatasets: [
        //   // { pack: 'csv', color: 'success', syncdate: '', link: '', loading: false, icon: 'mdi-table', title: 'CSV', label: 'mpref.dset-csv', selected: false },
        //   { pack: 'excel', color: 'info', syncdate: '', link: '', loading: false, icon: 'mdi-microsoft-excel', title: 'Excel', label: 'mpref.dset-excel', selected: false },
        //   { pack: 'pexcel', color: 'blue', syncdate: '', link: '', loading: false, icon: 'mdi-microsoft-excel', title: 'pExcel', label: 'mpref.dset-pexcel', selected: false },
        //   { pack: 'stata', color: 'warning', syncdate: '', link: '', loading: false, icon: 'mdi-database', title: 'Stata', label: 'mpref.dset-stata', selected: false },
        //   { pack: 'rds', color: 'indigo', syncdate: '', link: '', loading: false, icon: 'mdi-alpha-r-circle', title: 'Rds', label: 'mpref.dset-r', selected: false },
        //   { pack: 'spss', color: 'primary', syncdate: '', link: '', loading: false, icon: 'mdi-database', title: 'SPSS', label: 'mpref.dset-spss', selected: false },
        //   { pack: 'sas', color: 'error', syncdate: '', link: '', loading: false, icon: 'mdi-database', title: 'SAS', label: 'mpref.dset-sas', selected: false },
        //   { pack: 'arcgis', color: 'secondary', syncdate: '', link: '', loading: false, icon: 'mdi-earth', title: 'ArcGIS', label: 'mpref.dset-arcgis', selected: false },
        // ],

        dialogGis: false,
        selectedSection: '',
        questions: [],
        lat: '',
        lon: '',
        selectedDataset: {},

        syncLinks: {},
        syncDate: '',

        loading: false,

        // New snackbar notification
        snackBar: {
          type: 'success',
          mode: 'multi-line',
          direction: 'top center',
          timeout: 3000,
          title: '',
          text: '',
          visible: false,
        },

        // check submisions
        polling: null,

        // Default no blocks
        hasBlock: false,

        // Multimedia download options
        formMediaState: false,
        dwnoption: 'uuid',
        checkstart: false,
        checkend: false,
        uuid: '',

        // selectedLang: { code: '', full: '', short: '' },
        langList: {},

        // Date pickers
        sdate: this.$store.state.main.af.created.substr(0, 10),
        sdmenu: false,
        edate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        edmenu: false,
        // Time picker
        stime: '00:00',
        stmenu: false,
        etime: new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }),
        etmenu: false,

        maxdate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

        // Dialogs
        dialogBlocks: false,
        dialogGetmedia: false,
        dialogRestore: false,
      }
    },

    computed: {
      // Return only selected types of datasets
      finalDataset () {
        // console.log(this.dDatasets[0].syncdate)
        // console.log(Date() - this.dDatasets[0].syncdate)
        return this.dDatasets.filter(function (ds) {
          return ds.selected
        })
      },

      // Keep dBlock info open or closed
      dBInfo: {
        get () {
          return this.$store.state.main.dsb.fsettings.dDatasetInfo
        },
        set (value) {
          this.$store.commit('main/setfSetting', { el: 'dDatasetInfo', val: value })
          // console.log(this.$store.state.main.dsb.fsettings)
          this.dBlockSave('fsettings', this.$store.state.main.dsb.fsettings)
        },
      },

      dataSet () {
        return this.$store.state.ocpu.tableListRules
      },
    },

    created () {
      const main = this.$store.state.main
      // Fetching After Navigation
      // Fetch the data when the view is created and the data is already being observed
      // https://router.vuejs.org/guide/advanced/data-fetching.html#fetching-after-navigation

      // Periodically check submissions if form state is not closed
      if (main.af.odksettings.state !== 'closed') {
        // function from mixin dashboard.js
        this.checkSubmissions()
      }

      // console.log('created')
      this.getdDataset()

      this.getLanglist()

      this.getfMediaState()
    },

    mounted () {
      const main = this.$store.state.main

      // If this a first visit get dashboard blocks from ocpu
      if (main.dsb.calls === false) {
        // // Get Table list from ocpu
        // this.getTableList()

        // // Get block info from ocpu dBlocks - dashboarBlocks; dDataset - dashboardDataset; fsettings - Form settings
        // const blocks = ['fsettings', 'dBlocks']
        // blocks.forEach(type => {
        //   // Using function in mixin dashboard.js
        //   this.dBlockGet(type)
        // })
      }
      this.helptoolbar = this.$store.state.main.dsb.fsettings.dDatasetInfo

      // Get if any new submissions (function from mixin dashboard.js)
      this.getSubmissions()
    },

    beforeDestroy () {
      // We need clear interval as we run checkSubmissions()
      clearInterval(this.polling)
    },

    methods: {
      translateMe (val) {
        return this.$t(val)
      },

      // Update this.dDatasets value (selected) to true according to packs array list
      async getdDataset () {
        // Wait while we get data from ocpu
        await this.dBlockGet('dDataset')

        // selected datasets as array
        const selectedDset = this.$store.state.main.dsb.dDataset.filter(x => x.selected === true).map(x => x.pack)

        // Update this.dDatasets
        this.dDatasets.map(dSet => {
          if (selectedDset.includes(dSet.pack)) {
            dSet.selected = true

            // get Syncronization data for each selected dataset
            const dsname = dSet.title
            // console.log(dsname)
            this.getdsSyncData(dsname.toLowerCase())
          }
        })
        this.loadingdata = false
      },

      getLanglist () {
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const main = this.$store.state.main
        const props = { orgid: main.orgid, prjid: main.ap.prjid, formid: main.afid }

        return new Promise((resolve, reject) => {
          this.$httpOcpu.post(`/${ocpuPack}/R/getlanglist/json`, props)
            .then(response => {
              // console.log(response)
              this.langList = response.data
              resolve(response)
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
        })
      },

      // When one of Dataset checkboxes are selected / unselected
      dDatasetSelected (prop) {
        // console.log(prop)
        this.selectedDset = prop
        if (prop.pack === 'arcgis' && prop.state === true) {
          this.dialogGis = true
        } else {
          // Mutate state for other files, ArcGis is mutated in gisSelected()
          this.$store.commit('main/setdDatasetState', prop)
          // update ocpu
          this.dBlockSave('dDataset', this.$store.state.main.dsb.dDataset)
        }
      },

      sectionSelected (section) {
        // console.log(selectedTable.column)
        this.questions = section.column
      },

      // When ArcGis file selection is cancelled
      gisCancelled () {
        this.dialogGis = false
        // unselect it in dDatasets
        const gis = this.dDatasets.filter(x => x.pack === 'arcgis')
        gis[0].selected = false
        // Mutate state
        this.selectedDset.selected = false
        this.$store.commit('main/setdDatasetState', this.selectedDset)
        // update ocpu
        this.dBlockSave('dDataset', this.$store.state.main.dsb.dDataset)
      },

      // When gis Dataset checkboxes is selected
      gisSelected () {
        this.dialogGis = false
        // Mutate state for ArcGis
        this.$store.commit('main/setdDatasetState', this.selectedDset)
        // update ocpu
        this.dBlockSave('dDataset', this.$store.state.main.dsb.dDataset)
      },

      restoreDataset () {
        this.loading = true
        this.dialogRestore = false
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const orgid = this.$store.state.bend.organization.orgid
        const prjid = this.$store.state.main.ap.prjid
        const formid = this.$store.state.main.afid
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/restorestables/json`, {
              orgid: orgid,
              prjid: prjid,
              formid: formid,
            })
            .then(response => {
              this.loading_mtools = false
              // console.log(response)
              if (response.status === 201) {
                this.snackBar = {
                  type: 'success',
                  mode: 'multi-line',
                  direction: 'bottom center',
                  title: `${this.$t('common.success')}`,
                  text: `${this.$t('mpref.dset-restore-success')}`,
                  visible: true,
                }
                resolve(response)
              }
              this.loading = false
            })
            .catch(error => {
              this.snackBar = {
                type: 'error',
                mode: 'multi-line',
                title: `${this.$t('common.error')}`,
                text: `${this.$t('mpref.dset-restore-error')}`,
                visible: true,
              }
              // console.log(error)
              reject(error)
            })
        })
      },

      // Constructs download link for datasets
      dataLinks (dDataset) {
        // console.log(dDataset)
        if (dDataset.link.length > 0) {
          // console.log(dDataset)
          const sLink = dDataset.link
          const fileName = sLink.substring(sLink.lastIndexOf('/') + 1)
          // console.log(fileName)
          axios({
            url: sLink,
            method: 'GET',
            responseType: 'blob',
          }).then(response => {
            // console.log(response)
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', fileName)
            document.body.appendChild(fileLink)
            fileLink.click()
            window.URL.revokeObjectURL(fileURL)
          }).catch(error => {
            // console.log(error.response.status)
            if (error.response.status === 400) {
              this.snackBar = {
                type: 'error',
                mode: 'multi-line',
                text: 'No file existing. Please, generate a new file.',
                visible: true,
              }
            }
          })
        }
      },

      // Get Sync details and update
      getfMediaState () {
        // console.log(dsname)
        if (this.$store.state.ocpu.formMedia === null) {
          const ocpuPack = this.$store.state.ocpu.ocpuPack
          const main = this.$store.state.main
          const prop = {
            orgid: main.orgid,
            prjid: main.ap.prjid,
            formid: main.afid,
          }
          return new Promise((resolve, reject) => {
            this.$httpOcpu
              .post(`/${ocpuPack}/R/getfmediastate/json`, prop)
              .then(response => {
                // console.log(response)
                if (response.status === 201) {
                  this.formMediaState = response.data[0]
                  resolve()
                }
              })
              .catch(error => {
                // console.log(error)
                reject(error)
              })
          })
        }
      },

      // download multimedia files
      getMedia () {
        // Some needed functions
        // convert Hours to Minutes
        const convertH2M = (timeInHour) => {
          const timeParts = timeInHour.split(':')
          return Number(timeParts[0]) * 60 + Number(timeParts[1])
        }
        // make number as two digist number (9 > 09)
        const padTo2Digits = (num) => {
          return num.toString().padStart(2, '0')
        }
        // convert Minutes to Hours
        const convertM2H = (totalMinutes) => {
          const minutes = totalMinutes % 60
          const hours = Math.floor(totalMinutes / 60)

          return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`
        }
        // calculate timezone offset
        const offset = new Date().getTimezoneOffset()

        // let prop = ''
        const props = {
          orgid: this.$store.state.bend.organization.orgid,
          prjid: this.$store.state.main.ap.prjid,
          formid: this.$store.state.main.afid,
          uuid: '',
          startdate: '',
          enddate: '',
        }

        if (this.dwnoption === 'uuid') {
          props.uuid = this.uuid
        } else {
          // prepare start time if with offset it is < 0 we need to use 00:00
          const newstime = convertH2M(this.stime) + offset < 0 ? 0 : convertH2M(this.stime) + offset
          const starttime = convertM2H(newstime)

          props.startdate = `${this.sdate} ${starttime}:00 UTC`

          if (this.checkstart) {
            // prepate end time
            const newetime = convertH2M(this.etime) + offset < 0 ? 0 : convertH2M(this.etime) + offset
            const endtime = convertM2H(newetime)
            props.enddate = `${this.edate} ${endtime}:00 UTC`
          }
          // const newetime = convertH2M(this.etime) + offset
          // console.log(convertM2H(newetime))
        }
        // console.log(props)
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/ocmediafiles/json`, props)
            .then(response => {
              // console.log(response)
              const link = `${response.headers.location}/files/${response.data[0]}`
              const fileName = response.data[0]
              // console.log(link)
              // console.log(fileName)
              this.dialogGetmedia = false
              axios({
                url: link,
                method: 'GET',
                responseType: 'blob',
              }).then(response => {
                const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                const fileLink = document.createElement('a')
                fileLink.href = fileURL
                fileLink.setAttribute('download', fileName)
                document.body.appendChild(fileLink)
                fileLink.click()
                window.URL.revokeObjectURL(fileURL)
              })
            })
            .catch(error => {
              this.snackBar = {
                type: 'warning',
                mode: 'multi-line',
                title: `${this.$t('common.error')}`,
                text: `${this.$t('export.nomedia-download')}`,
                visible: true,
              }
              // console.log(error)
              reject(error)
            })
        })
      },

    },
  }
</script>

<style lang="sass">
  @import "~leaflet/dist/leaflet.css"
  @import "~leaflet.markercluster/dist/MarkerCluster.css"
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css"

  #coloured-line
    .ct-series-a .ct-line,
    .ct-series-a .ct-point
      stroke: #00cae3 !important

    .ct-series-b .ct-bar
      stroke: #f44336 !important

  #progressPie
    .ct-series-a .ct-slice-pie
      fill: #659D32 !important

    .ct-series-b .ct-slice-pie
      fill: #FF8C00 !important
</style>
